import React from 'react';
import './projectperformance.css';
import Sidebar from '../sidebar/Sidebar';

const ProjectFinance = () => {
    return (
        <div className="project-container">
            <Sidebar className="project-sidebar" />
            <div className="project-content">
                <div className="project-content-details">
                    <header className="project-header" id="introduction">
                        <h1>Finance Web App</h1>
                        <p>
                            <i>
                                A comprehensive overview of building a personal
                                finance website
                            </i>
                        </p>
                    </header>

                    <section className="project-section" id="problem-statement">
                        <h2>Problem Statement</h2>
                        <p>
                            When it comes to managing my personal finances, I
                            sometimes find myself needing a more convenient and
                            efficient way of working through my spending habits.
                            Things that I find benecificial are listed below:
                            <ul>
                                <li>1. Privatizing my personal finances</li>
                                <li>2. Digital storage of transactions</li>
                                <li>
                                    3. Automate taxes paid through purchases
                                </li>
                                <li>4. Visual Graphs</li>
                            </ul>
                        </p>
                    </section>

                    <section className="project-section" id="approach">
                        <h2>Approach and Methodology</h2>
                        <p>
                            <div className="section">
                                <h3>Introduction</h3>
                                <p>
                                    In this section, I outline the structured
                                    approach and methodology used to develop a
                                    personal finance web application. This
                                    application is designed to help users manage
                                    their income, expenses, and savings
                                    efficiently. The project involved several
                                    key stages: planning, design, development,
                                    testing, and deployment.
                                </p>
                            </div>
                            <div className="section">
                                <h3>Planning and Research</h3>
                                <ul>
                                    <li>
                                        <strong>Objective Definition:</strong>{' '}
                                        Clearly defined the primary goal of the
                                        application: to provide an intuitive
                                        platform for users to track their
                                        financial activities.
                                    </li>
                                    <li>
                                        <strong>Market Research:</strong>{' '}
                                        Conducted research on existing personal
                                        finance tools to identify gaps and
                                        unique value propositions.
                                    </li>
                                    <li>
                                        <strong>
                                            User Personas and Use Cases:
                                        </strong>{' '}
                                        Developed detailed user personas and
                                        scenarios to understand potential user
                                        needs and behaviors.
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Design</h3>
                                <ul>
                                    <li>
                                        <strong>
                                            Wireframing and Prototyping:
                                        </strong>{' '}
                                        Created wireframes and interactive
                                        prototypes using tools like Figma to
                                        visualize the user interface and user
                                        experience.
                                    </li>
                                    <li>
                                        <strong>UI/UX Principles:</strong>{' '}
                                        Applied best practices in UI/UX design
                                        to ensure the application is
                                        user-friendly, accessible, and visually
                                        appealing.
                                    </li>
                                    <li>
                                        <strong>Feedback Loop:</strong>{' '}
                                        Conducted usability testing with
                                        potential users to gather feedback and
                                        refine the design iteratively.
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Development</h3>
                                <ul>
                                    <li>
                                        <strong>
                                            Technology Stack Selection:
                                        </strong>{' '}
                                        Chose a modern and scalable technology
                                        stack:
                                        <ul>
                                            <li>
                                                <strong>Frontend:</strong>{' '}
                                                React.js for a dynamic and
                                                responsive user interface.
                                            </li>
                                            <li>
                                                <strong>Backend:</strong>{' '}
                                                Node.js with Express for
                                                handling server-side logic.
                                            </li>
                                            <li>
                                                <strong>Database:</strong>{' '}
                                                MongoDB for flexible and
                                                document-oriented data storage.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Agile Methodology:</strong>{' '}
                                        Utilized Agile practices to manage the
                                        development process, with regular
                                        sprints and stand-up meetings to ensure
                                        continuous progress and adaptability to
                                        changes.
                                    </li>
                                    <li>
                                        <strong>Feature Implementation:</strong>{' '}
                                        Developed core features including:
                                        <ul>
                                            <li>
                                                <strong>Dashboard:</strong> A
                                                comprehensive overview of
                                                financial status, including
                                                charts and summaries.
                                            </li>
                                            <li>
                                                <strong>
                                                    Transaction Management:
                                                </strong>{' '}
                                                Easy input and categorization of
                                                income and expenses.
                                            </li>
                                            <li>
                                                <strong>
                                                    Budgeting Tools:
                                                </strong>{' '}
                                                Features for setting and
                                                tracking budgets.
                                            </li>
                                            <li>
                                                <strong>
                                                    Security Measures:
                                                </strong>{' '}
                                                Implemented authentication
                                                protocols and data encryption to
                                                protect user information.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Testing</h3>
                                <ul>
                                    <li>
                                        <strong>
                                            Unit and Integration Testing:
                                        </strong>{' '}
                                        Employed testing frameworks like Jest
                                        and Mocha to ensure the reliability of
                                        individual components and overall system
                                        functionality.
                                    </li>
                                    <li>
                                        <strong>
                                            User Acceptance Testing (UAT):
                                        </strong>{' '}
                                        Conducted UAT sessions with a group of
                                        users to validate the application
                                        against requirements and gather feedback
                                        for improvements.
                                    </li>
                                    <li>
                                        <strong>Performance Testing:</strong>{' '}
                                        Analyzed the application’s performance
                                        under various conditions to ensure
                                        scalability and responsiveness.
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Deployment</h3>
                                <ul>
                                    <li>
                                        <strong>Environment Setup:</strong>{' '}
                                        Configured staging and production
                                        environments using cloud services such
                                        as AWS or Heroku for scalability and
                                        reliability.
                                    </li>
                                    <li>
                                        <strong>
                                            Continuous Integration/Continuous
                                            Deployment (CI/CD):
                                        </strong>{' '}
                                        Set up CI/CD pipelines to automate
                                        testing and deployment processes.
                                    </li>
                                    <li>
                                        <strong>
                                            Monitoring and Maintenance:
                                        </strong>{' '}
                                        Implemented monitoring tools to track
                                        application performance and user
                                        engagement, ensuring timely updates and
                                        maintenance.
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Conclusion</h3>
                                <p>
                                    This personal finance web application was
                                    developed with a user-centric approach,
                                    focusing on delivering a seamless and secure
                                    financial management experience. My
                                    methodology combined thorough planning,
                                    modern technology, and iterative feedback to
                                    create a solution that effectively meets
                                    user needs.
                                </p>
                            </div>
                        </p>
                    </section>

                    <section className="project-section" id="implementation">
                        <h2>Implementation</h2>
                        <p>
                            <div className="section">
                                <h3>Introduction</h3>
                                <p>
                                    The implementation phase of the personal
                                    finance web application involved turning
                                    design concepts into a functional product.
                                    This section details the technical
                                    execution, highlighting key features, coding
                                    practices, and tools used to bring the
                                    application to life.
                                </p>
                            </div>

                            <div className="section">
                                <h3>Frontend Implementation</h3>
                                <ul>
                                    <li>
                                        <strong>
                                            Framework and Libraries:
                                        </strong>{' '}
                                        Leveraged React.js for building a
                                        dynamic and responsive user interface.
                                        Utilized libraries like Redux for state
                                        management and React Router for
                                        navigation.
                                    </li>
                                    <li>
                                        <strong>
                                            Component-Based Architecture:
                                        </strong>{' '}
                                        Designed reusable and modular components
                                        to promote code maintainability and
                                        scalability.
                                    </li>
                                    <li>
                                        <strong>Responsive Design:</strong>{' '}
                                        Ensured the application is accessible on
                                        various devices by implementing
                                        responsive design principles and CSS
                                        frameworks like Bootstrap or Tailwind
                                        CSS.
                                    </li>
                                    <li>
                                        <strong>API Integration:</strong>{' '}
                                        Developed RESTful API calls to interact
                                        with backend services, enabling seamless
                                        data fetching and updating.
                                    </li>
                                </ul>
                            </div>

                            <div className="section">
                                <h3>Backend Implementation</h3>
                                <ul>
                                    <li>
                                        <strong>Server Setup:</strong> Built the
                                        server using Node.js and Express,
                                        providing a robust infrastructure for
                                        handling application logic and data
                                        processing.
                                    </li>
                                    <li>
                                        <strong>Database Management:</strong>{' '}
                                        Implemented a MongoDB database to store
                                        user data securely. Designed data
                                        schemas to support efficient querying
                                        and data manipulation.
                                    </li>
                                    <li>
                                        <strong>
                                            Authentication and Authorization:
                                        </strong>{' '}
                                        Integrated authentication mechanisms
                                        using JSON Web Tokens (JWT) to ensure
                                        secure access to user accounts and data.
                                    </li>
                                    <li>
                                        <strong>Business Logic:</strong>{' '}
                                        Encapsulated core business logic within
                                        service layers, allowing for easy
                                        maintenance and scalability.
                                    </li>
                                </ul>
                            </div>

                            <div className="section">
                                <h3>Key Features</h3>
                                <ul>
                                    <li>
                                        <strong>Dashboard:</strong> Developed a
                                        comprehensive dashboard that aggregates
                                        financial data, offering users insights
                                        through visualizations and analytics.
                                    </li>
                                    <li>
                                        <strong>Transaction Handling:</strong>{' '}
                                        Implemented features for adding,
                                        editing, and categorizing financial
                                        transactions, ensuring accurate tracking
                                        of expenses and income.
                                    </li>
                                    <li>
                                        <strong>Budgeting Tools:</strong>{' '}
                                        Developed modules for setting financial
                                        goals and tracking budgets over time,
                                        with real-time feedback and alerts.
                                    </li>
                                    <li>
                                        <strong>Data Security:</strong> Employed
                                        encryption techniques to protect
                                        sensitive user data both in transit and
                                        at rest.
                                    </li>
                                </ul>
                            </div>
                            <div className="section">
                                <h3>Development Tools and Practices</h3>
                                <ul>
                                    <li>
                                        <strong>Version Control:</strong> Used
                                        Git for version control, facilitating
                                        efficient collaboration and code
                                        management.
                                    </li>
                                    <li>
                                        <strong>
                                            Development Environment:
                                        </strong>{' '}
                                        Set up a local development environment
                                        with tools like Docker to ensure
                                        consistency across different stages.
                                    </li>
                                    <li>
                                        <strong>Code Quality:</strong> Adhered
                                        to coding standards and best practices,
                                        incorporating tools like ESLint and
                                        Prettier for code linting and
                                        formatting.
                                    </li>
                                    <li>
                                        <strong>Testing:</strong> Automated
                                        testing using Jest and Mocha to validate
                                        the functionality and reliability of
                                        both frontend and backend components.
                                    </li>
                                </ul>
                            </div>

                            <div className="section">
                                <h3>Challenges and Solutions</h3>
                                <ul>
                                    <li>
                                        <strong>Data Synchronization:</strong>{' '}
                                        Managed complexities in data
                                        synchronization between frontend and
                                        backend by implementing real-time
                                        updates using WebSockets.
                                    </li>
                                    <li>
                                        <strong>Scalability:</strong> Addressed
                                        scalability concerns by optimizing
                                        database queries and leveraging caching
                                        mechanisms.
                                    </li>
                                    <li>
                                        <strong>User Experience:</strong>{' '}
                                        Improved user experience through
                                        iterative testing and feedback, focusing
                                        on usability and intuitive design.
                                    </li>
                                    <li>
                                        <strong>Security:</strong> Implemented
                                        advanced security practices such as
                                        input validation and regular security
                                        audits to protect against
                                        vulnerabilities.
                                    </li>
                                </ul>
                            </div>

                            <div className="section">
                                <h3>Conclusion</h3>
                                <p>
                                    The implementation of the personal finance
                                    web application was a comprehensive process
                                    that involved leveraging modern technologies
                                    and practices to deliver a robust,
                                    user-centric product. By focusing on modular
                                    design, efficient data handling, and
                                    user-friendly features, the application
                                    successfully met its objectives. Continuous
                                    integration of feedback and technological
                                    advancements will ensure its relevance and
                                    effectiveness in helping users manage their
                                    finances.
                                </p>
                            </div>
                        </p>
                    </section>

                    <section className="project-section" id="results">
                        <h2>Results and Evaluation</h2>
                        <p>
                            Present the results using performance metrics,
                            graphs, or tables. Provide snippets of different
                            features of the application.
                        </p>
                    </section>

                    <section className="project-section" id="challenges">
                        <h2>Challenges and Learnings</h2>
                        <p>
                            Discuss obstacles faced and key learnings from the
                            project.
                        </p>
                    </section>

                    <section className="project-section" id="conclusion">
                        <h2>Conclusion</h2>
                        <p>
                            Summarize the project's objectives, outcomes, and
                            future work suggestions.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ProjectFinance;
