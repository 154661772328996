import React, { useState } from 'react';

const DevOps = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="skills__content" onClick={() => toggleTab(1)}>
                <h3 className="skills__title">DevOps</h3>

                <div className="skills__box">
                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">VCS</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">IaC</h3>
                                <span className="skills__level">Basic</span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Containers</h3>
                                <span className="skills__level">Basic</span>
                            </div>
                        </div>
                    </div>

                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Cloud Services</h3>
                                <span className="skills__level">Basic</span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Monitoring</h3>
                                <span className="skills__level">Basic</span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Logging</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skills__box-footer">
                    <span
                        className="skills__button"
                        onClick={() => toggleTab(1)}
                    >
                        View More Details
                        <i className="uil uil-arrow-right skills__button-icon"></i>
                    </span>
                </div>
            </div>
            <div
                className={
                    toggleState === 1
                        ? 'skills__modal active-modal'
                        : 'skills__modal'
                }
            >
                <div className="skills__modal-content">
                    <i
                        onClick={() => toggleTab(0)}
                        className="uil uil-times skills__modal-close"
                    ></i>

                    <h3 className="skills__modal-title">Dev Operations</h3>
                    <p className="skills__modal-description">
                        A list of my skillsets and familiar technology stacks I
                        have experience with implementing CI/CD Lifecycles.
                    </p>

                    <ul className="skills__modal-services grid">
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://octopus.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>Octopus Deploy </strong>
                                </a>
                                <i>
                                    Deploying software to multi-cloud, hybrid,
                                    and on-premises environments with ease
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://www.terraform.io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>Terraform </strong>
                                </a>
                                <i>
                                    Eliminating the manual redundant process of
                                    building software infrastructure by
                                    automation through the use of Terraform on
                                    any cloud platform.
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://docs.github.com/en/actions/about-github-actions/understanding-github-actions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>GitHub Actions </strong>
                                </a>
                                <i>
                                    (CI/CD) platform that allows you to automate
                                    your build, test, and deployment pipeline
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://aws.amazon.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>AWS </strong>
                                </a>
                                <i>
                                    on-demand cloud computing platforms and
                                    APIs.
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://www.datadoghq.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>DataDog </strong>
                                </a>
                                <i>
                                    used for monitoring & security See inside
                                    any stack, any app, at any scale, anywhere.
                                </i>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DevOps;
