import React, { useState } from 'react';
import './qualification.css';

const Qualification = () => {
    const [toggleState, setToggleState] = useState(2);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <section className="qualification section" id="qualification">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">
                <i>My Personal Journey</i>
            </span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div
                        className={
                            toggleState === 2
                                ? 'qualification__button qualification__active button--flex'
                                : 'qualification__button button--flex'
                        }
                        onClick={() => toggleTab(2)}
                    >
                        <i className="uil uil-briefcase-alt qualification__icon"></i>
                        Experience
                    </div>
                    <div
                        className={
                            toggleState === 1
                                ? 'qualification__button qualification__active button--flex'
                                : 'qualification__button button--flex'
                        }
                        onClick={() => toggleTab(1)}
                    >
                        <i className="uil uil-graduation-cap qualification__icon"></i>
                        Education
                    </div>
                </div>
                <div className="qualification__sections">
                    <div
                        className={
                            toggleState === 1
                                ? 'qualification__content qualification__content-active'
                                : 'qualification__content'
                        }
                    >
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Pacific Luthern University
                                </h3>
                                <span className="qualification__subtitle">
                                    Tacoma - Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2014 - 2017
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        {/* <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    Ux Designer
                                </h3>
                                <span className="qualification__subtitle">
                                    Apple Inc - Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2020 - 2021
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Web Development
                                </h3>
                                <span className="qualification__subtitle">
                                    Spain - Institute
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2018 - 2020
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div> */}
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? 'qualification__content qualification__content-active'
                                : 'qualification__content'
                        }
                    >
                        {/* <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    United States Army
                                </h3>
                                <span className="qualification__subtitle">
                                    Joint Base Lewis-McChord, Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2009 - 2017
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div> */}

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    Pacific Northwest National Laboratory
                                </h3>
                                <span className="qualification__subtitle">
                                    Richland, Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2018
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    South Sound 911
                                </h3>
                                <span className="qualification__subtitle">
                                    Tacoma, Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2017 - 2022
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div></div>
                        </div>

                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                {/* <span className="qualification__line"></span> */}
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    Tyler Technologies
                                </h3>
                                <span className="qualification__subtitle">
                                    Renton, Washington
                                </span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2022 - Present
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qualification;
