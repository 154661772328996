import React, { useState } from 'react';

import './projects.css';

const Projects = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="projects section" id="projects">
            <h2 className="section__title">Projects</h2>
            <span className="section__subtitle">
                <i>What I have worked on</i>
            </span>

            <div className="projects__container container grid">
                <div className="projects__content">
                    <div>
                        <i class="uil uil-dashboard projects__icon"></i>
                        <h3 className="projects__title">
                            Optimizing Microservice <br /> Performances
                            <br />
                        </h3>

                        <span className="projects__subtitle">
                            .NET 8 Performance Improvements
                        </span>
                    </div>

                    <span
                        className="projects__button"
                        onClick={() => toggleTab(1)}
                    >
                        View More
                        <i className="uil uil-arrow-right projects__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 1
                                ? 'projects__modal active-modal'
                                : 'projects__modal'
                        }
                    >
                        <div className="projects__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times projects__modal-close"
                            ></i>

                            <h3 className="projects__modal-title">
                                Optimizing Microservice Performance
                            </h3>
                            <p className="projects__modal-description">
                                Listed below are some objectives for improving
                                the performance of microservices I have handled.
                            </p>

                            <ul className="projects__modal-projects grid">
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Collaborated with product owners and
                                        engineers on refining scope of work and
                                        use cases.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Staged performance data to overload our
                                        integrated processes based on
                                        client-based requirement.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Performed stress testing of use cases.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Recorded performance results for
                                        improvement analysis.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Applied performance improvements based
                                        on dotnet 8 upgrade.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="projects__content">
                    <div>
                        <i class="uil uil-dollar-alt projects__icon"></i>{' '}
                        <h3 className="projects__title">
                            Finance <br /> Web Application
                        </h3>
                        <span className="projects__subtitle">
                            Receipt Processor
                        </span>
                    </div>

                    <span
                        className="projects__button"
                        onClick={() => toggleTab(2)}
                    >
                        View More
                        <i className="uil uil-arrow-right projects__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 2
                                ? 'projects__modal active-modal'
                                : 'projects__modal'
                        }
                    >
                        <div className="projects__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times projects__modal-close"
                            ></i>

                            <h3 className="projects__modal-title">
                                Finance Web Application
                            </h3>
                            <p className="projects__modal-description">
                                Created a web-based finance application for
                                receipts processing.
                            </p>

                            <ul className="projects__modal-projects grid">
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Built web application from the ground
                                        up.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Frontend: React & NodeJS
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Backend: .NET 8, PostgresSQL
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Containerized using docker.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Message bus integrated (RabbitMQ) for
                                        microservices architecture
                                        communication.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="projects__content">
                    <div>
                        <i className="uil uil-constructor projects__icon"></i>
                        <h3 className="projects__title">
                            Building Domain-Specific <br /> Microservices
                        </h3>

                        <span className="projects__subtitle">
                            Production ready services for enterprise apps.
                        </span>
                    </div>

                    <span
                        className="projects__button"
                        onClick={() => toggleTab(3)}
                    >
                        View More
                        <i className="uil uil-arrow-right projects__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 3
                                ? 'projects__modal active-modal'
                                : 'projects__modal'
                        }
                    >
                        <div className="projects__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times projects__modal-close"
                            ></i>

                            <h3 className="projects__modal-title">
                                Building Domain Specific Microservices
                            </h3>
                            <p className="projects__modal-description">
                                From enterprise systems to my Financial Web
                                Application, constructing microservices to serve
                                domain specific needs.
                            </p>

                            <ul className="projects__modal-projects grid">
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Utilizing accelerator templates for fast
                                        microservice implementations.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Applying Fast REST API protocols.
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Containerized Microservices using Docker
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        RabbitMQ for containerized microservice
                                        inter-communications
                                    </p>
                                </li>
                                <li className="projects__modal-project">
                                    <i className="uil uil-check-circle projects__modal-icon"></i>
                                    <p className="projects__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Projects;
